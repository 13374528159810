import React from "react";
import Template from "../../assets/book-saved.svg";
import Blank from "../../assets/grid-7.svg";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AddCourseTypeModal = ({ handleClose }) => {
  const navigate = useNavigate();
  const List = [
    {
      icon: Blank,
      text: "Use template",
      action: () => {
        navigate("/courses/template");
        handleClose();
      },
    },
    {
      icon: Template,
      text: "blank course",
      action: () => navigate("/courses/add-course-new"),
    },
  ];
  return (
    <div>
      <div
        onClick={(e) => e.target === e.currentTarget && handleClose()}
        className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center"
      >
        <div className="tw-w-56 tw-h-56 tw-rounded-xl tw-bg-white tw-p-5">
          <div className="tw-flex tw-justify-end">
            <AiOutlineClose
              className="tw-cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <h4 className="tw-text-xl tw-font-bold satoshi tw-text-secondary">
            Add Course
          </h4>
          <div className="tw-mt-6 tw-space-y-4">
            {List.map((it, x) => (
              <div
                onClick={it?.action}
                key={x}
                className="tw-flex tw-cursor-pointer tw-items-center tw-space-x-4"
              >
                <img src={it?.icon} alt="" className="tw-w-8 tw-h-8" />
                <h6 className="tw-text-sm tw-capitalize tw-text-main satoshi tw-font-normal">
                  {it?.text}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourseTypeModal;
