import React from "react";
import { TextInput } from "../inputs";
import { MainBtn } from "../button";

const AddCouponModal = ({ handleClose }) => {
  return (
    <div>
      <div
        onClick={(e) => e.target === e.currentTarget && handleClose()}
        className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center"
      >
        <div className="lg:tw-w-[30%] tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-max-h-[80vh] tw-rounded-xl  noScroll tw-overflow-y-auto">
          <div>
            <h3 className="tw-text-xl tw-font-bold tw-text-[#0269D0]">
              Add Coupon
            </h3>
            <form action="" className="tw-mt-6">
              <div className="tw-space-y-4">
                <TextInput label={"Coupon code"} />
                <TextInput label={"Discount type"} />
                <TextInput label={"Percentage"} />
                <TextInput type={"date"} label={"Expiry date"} />
                <TextInput label={"Maximum usage"} />
              </div>
              <div className="tw-mt-7">
                <MainBtn text={"Add Coupon"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCouponModal;
