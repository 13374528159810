import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { TextArea, TextInput, VideoInput } from "../../components/inputs";
import Dots from "../../assets/dots.svg";
import Add from "../../assets/add-square.svg";
import { Button, MainBtn } from "../../components/button";
import { MainTemplate } from "./single-template";
import AddCouponModal from "../../components/modals/add-coupon";
import { FaRegCopy } from "react-icons/fa6";
import useCourseStore from "../../data/stores/coursestore";
import useErrorStore from "../../data/stores/errorstore";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { apiCall } from "../../data/stores/authstore";
import { AiOutlineClose } from "react-icons/ai";
import Duplicate from "../../assets/document-copy.svg";
import Delete from "../../assets/trash.svg";
import Copy from "../../assets/Duplicate.svg";
import Save from "../../assets/Save.svg";
import { AddCourseQuiz } from "./add-course";

const AddCourseNew = () => {
	const [step, setStep] = useState(1),
		[getSearch] = useSearchParams(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null),
		[newUpload, setNewUpload] = useState(null);

	useEffect(() => {
		let typer = getSearch?.get("type");
		if (typer) {
			if (typer === "module") setStep(3);
			// if (typer === "section") setStep(3);
			// if (typer === "quiz") setStep(4);
		}
	}, [getSearch]);
	useEffect(() => {
		if (state) {
			setDatum(state);
		}
	}, [state]);
	// console.log({ datum, state });

	return (
		<div>
			<MainContainer>
				<h3 className="tw-text-base tw-font-bold tw-text-myblue satoshi">
					Add New Course<span className="tw-font-medium"> </span>
				</h3>
				<MainHeader text="Courses" small={"Add a new course"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					{step === 1 && (
						<AddCourseMain
							setStep={setStep}
							handleNext={d => {
								setDatum(d?.data || d);
								setNewUpload(true);
								setStep(3);
							}}
						/>
					)}
					{step === 2 && <MainTemplate />}
					{step === 3 && (
						<AddCourseDetail
							setStep={setStep}
							datum={
								getSearch?.get("type") === "module" || newUpload ? datum : null
							}
							course={getSearch?.get("course")}
						/>
					)}
					{/* {step === 4 && <AddAsessMent setStep={setStep} />} */}
					{step === 4 && (
						<AddCourseQuiz
							// setStep={setStep}
							footerComponent={() => (
								<>
									<div className="tw-mt-16 tw-flex tw-items-center tw-gap-6">
										<MainBtn onClick={() => setStep(5)} text={"Continue"} />
										<h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
											Skip
										</h6>
									</div>
								</>
							)}
							headerComponent={() => (
								<>
									<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
										Course Assessment
									</h4>
								</>
							)}
						/>
					)}
					{step === 5 && <AddPricing setStep={setStep} />}
				</div>
			</MainContainer>
		</div>
	);
};

const AddCourseMain = ({ setStep, datum, handleNext }) => {
	let init = {
			title: "",
			caption: "",
			duration: "",
			description: "",
			amount: "",
		},
		[state, setState] = useState(init),
		[loading, setLoading] = useState(false),
		// [isEdit, setIsEdit] = useState(false),
		textChange = ({ target: { value, name } }) => {
			setState(prev => {
				return { ...prev, [name]: value };
			});
		},
		{ addCourse, updateCourse } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[getSearch] = useSearchParams();

	useEffect(() => {
		if (datum) {
			setState({ ...datum, softwares: datum?.softwares?.toString() });
		}
	}, [datum]);

	let handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Product title is required");
		let newState = {
			...state,
			// softwares: state?.softwares?.split(",")?.map(item => item?.trim()),
		};
		if (
			state?.image &&
			!state?.image?._id &&
			typeof state?.image !== "string"
		) {
			newState.intendedFile = newState?.image;
			newState.intendedFileName = "image";
		}
		delete newState?.students;
		delete newState?.module;
		delete newState?.rating;
		delete newState?.resources;
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/course/manage-course${datum?._id ? `/${datum?._id}` : ""}`,
			newState,
			null,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		console?.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			if (datum?._id) updateCourse(response);
			else addCourse(response);
			setState(init);
			if (getSearch?.get("type")) navigate(-1);
			else if (handleNext) handleNext(response);
			else if (setStep) setStep(3);
			return;
		}
		setLoading(false);
	};

	useEffect(() => {
		if (datum) setState({ ...state, ...datum });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datum]);

	let handleChangeImage = (e, name, ty) => {
		const file = e?.target.files[0];
		let err = "";
		console.log({ err, file }, "one");
		if (!file) err = `File, ${file?.name} does not exist`;
		if (!file.type.includes(ty || "image"))
			err = `File, ${file?.name} format not supported`;
		console.log({ err, file });
		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [name || "image"]: file });
		}
	};

	return (
		<div>
			<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
				Course Detail and Module
			</h4>
			<form action="" className="">
				<div className="tw-space-y-4 tw-w-1/2">
					<TextInput
						label={"Name of Course"}
						value={state?.title}
						onChange={textChange}
						name={"title"}
					/>
					<TextInput
						label={"Course Amount"}
						value={state?.amount}
						setState={e => setState({ ...state, amount: e })}
						onChange={textChange}
						name={"amount"}
						type={"tel"}
					/>
					<TextInput
						label={"Total Video Play Length(mins)"}
						value={state?.duration}
						setState={e => setState({ ...state, duration: e })}
						onChange={textChange}
						name={"duration"}
						type={"tel"}
						noFormat
					/>
					<TextInput
						label={"Course Duration(weeks)"}
						value={state?.courseDuration}
						setState={e => setState({ ...state, courseDuration: e })}
						onChange={textChange}
						name={"courseDuration"}
						type={"tel"}
						noFormat
					/>
					<TextArea
						label={"Course overview"}
						value={state?.description}
						onChange={textChange}
						name="description"
						setState={e =>
							setState(prev => {
								let newPrev = { ...prev, description: e };
								return newPrev;
							})
						}
					/>
					<VideoInput
						accept="image/*"
						name="image"
						onChange={e => handleChangeImage(e, "videoLink", "video")}
						ty="image"
						state={state}
						setState={setState}
					/>
				</div>
				<div className="tw-mt-16 tw-flex tw-items-center tw-gap-6">
					{/* <MainBtn onClick={() => setStep(3)} text={"Continue"} /> */}
					<Button
						onClick={handleSubmit}
						loading={loading}
						children={"Continue"}
						type="submit"
					/>
					<h6
						onClick={() => {
							if (process.env.NODE_ENV === "development") {
								setStep(3);
							}
						}}
						className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer tw-hidden">
						Save as Template
					</h6>
				</div>
			</form>
		</div>
	);
};

const AddCourseDetail = ({ setStep, datum, course }) => {
	const [action, setAction] = useState(false);
	const [modules, setModules] = useState([
			{
				title: "",
			},
		]),
		textChange = ({ target: { value, name } }, index) => {
			setModules(prev => {
				let newPrev = [...prev];
				newPrev[index][name] = value;
				return newPrev;
			});
		},
		[currentIndex, setCurrentIndex] = useState(null),
		[loading, setLoading] = useState(false),
		{ updateCourse } = useCourseStore(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate();

		function extractFilenameAndConvert(base64String) {
			// Extract filename (if available) and MIME type
			const matches = base64String.match(/;name=(.*?);/);
			const filename = matches ? matches[1] : "default_filename";

			// Split Base64 string into parts
			const [header, base64Content] = base64String.split(",");

			// Decode Base64 and convert to File
			const binaryString = atob(base64Content);
			const byteArray = new Uint8Array(binaryString.length);
			for (let i = 0; i < binaryString.length; i++) {
				byteArray[i] = binaryString.charCodeAt(i);
			}

			const mimeType = header.match(/:(.*?);/)[1];
			return new File([byteArray], filename, { type: mimeType });
		}

		let handleSubmit = async e => {
			e?.preventDefault();
			if (!modules?.every(it => it?.title))
				return toast.info("Module title is required");
			if (!datum?._id && !course) return;
			let newState = {
				modules,
				course: datum?._id || course,
			};

			setLoading(true);

			let newModules = [];

			for (let m = 0; m < modules.length; m++) {
				let element = modules[m],
					elementSub = [];

				for (let s = 0; s < element?.moduleSection.length; s++) {
					let elementInner = element?.moduleSection[s];

					if (["image", "video", "quote"]?.includes(elementInner?.type))
						if (
							elementInner?.img?.startsWith("data:") ||
							elementInner?.avatar?.startsWith("data:")
						) {
							let logo = extractFilenameAndConvert(
								elementInner?.img || elementInner?.avatar
							);
							let { response, errArr, errMsg } = await apiCall("file", ``, {
								logo,
								query: `?returnOriginal=true`,
							});
							console?.log({ response, errArr, errMsg });
							if (errArr) {
								setLoading(false);
								return returnErrors(errArr);
							}
							if (errMsg) {
								setLoading(false);
								return toast.error(errMsg);
							}
							if (response) {
								let mainData = response?.data || response;
								mainData = mainData[0];

								console.log({ mainData, response });

								if (elementInner?.img) {
									elementInner.img = mainData?.playback_url || mainData?.url;
								} else if (elementInner?.avatar) {
									elementInner.avatar = mainData?.playback_url || mainData?.url;
								}
							}
						}
					elementSub?.push(elementInner);
				}
				newModules?.push({ ...element, moduleSection: elementSub });
			}

			newState.modules = newModules;
			let { response, errArr, errMsg } = await apiCall(
				"post",
				`/api/v1/course/manage-module/manage-content`,
				newState,
				null
				// {
				// 	headers: {
				// 		"Content-Type": "multipart/form-data",
				// 	},
				// }
			);
			console?.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				if (datum?._id || course) {
					updateCourse(response);
				}
				if (course) {
					navigate(-1);
				} else if (setStep) {
					navigate("/courses");
				}
				// } else if (setStep) setStep(4);
				// else addCourse(response);
				// setState(init);
				// if (getSearch?.get("type")) navigate(-1);
				// else if (handleNext) handleNext(e);
				// else if (setStep) setStep(3);
				return;
			}
			setLoading(false);
		};
	// console.log({ course, datum });
	const actions = [
		{
			icon: Duplicate,
			name: "duplicate",
			action: index => {
				setModules(prev => {
					const newList = [...prev],
						newD = { ...prev[index] };
					newD._id = null;
					newList.splice(index, 0, newD);
					return newList;
				});
			},
		},
		{
			icon: Copy,
			name: "copy",
		},
		{
			icon: Delete,
			name: "delete",
			action: index => {
				setModules(prev => {
					const newList = [...prev.filter((_, i) => i !== index)];
					return newList;
				});
			},
		},
		{
			icon: Save,
			name: "save as template",
		},
	];

	useEffect(() => {
		if (datum && datum?.module?.length > 0) {
			setModules(datum?.module);
		}
	}, [datum]);

	return (
		<>
			{currentIndex ? (
				<MainTemplate
					datum={modules?.[currentIndex - 1]}
					onUpdate={d => {
						setModules(prev => {
							let newPrev = [...prev];
							newPrev[currentIndex - 1]["moduleSection"] = d;
							return newPrev;
						});
						setCurrentIndex(null);
					}}
				/>
			) : (
				<div className="tw-w-1/2">
					<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
						Course Detail And Module
					</h4>
					<form action="" className="tw-mt-5">
						{/* <div className="tw-space-y-4">
          <TextInput label={"Number of Retakes"} placeholder={"1"} />
          <TextInput label={"Set Benchmark"} placeholder={"1"} />
        </div> */}
						{modules?.map((mod, i) => (
							<div className="form-group tw-py-3 tw-relative" key={i}>
								<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
									Module Details
								</p>
								<div className="tw-h-16 tw-w-full tw-border-[0.5px] tw-flex tw-items-center tw-border-secondary tw-rounded-lg tw-relative">
									<div>
										<input
											type="text"
											name="title"
											placeholder="Type here"
											value={mod?.title}
											onChange={e => textChange(e, i)}
											className="tw-h-12 tw-w-full tw-pl-6 tw-rounded-lg tw-border-none tw-outline-none tw-text-base tw-font-normal tw-text-secondary"
										/>
									</div>
									<div className="tw-absolute tw-right-3 tw-h-full tw-flex tw-gap-3 tw-items-center">
										<button
											type="button"
											onClick={() => setCurrentIndex(i + 1)}
											className="tw-h-9 tw-w-28 tw-bg-[#0269D0] tw-rounded-lg tw-text-sm tw-font-medium tw-text-white">
											Add Content
										</button>
										<img
											onClick={() => setAction(i + 1)}
											src={Dots}
											alt=""
											className="tw-cursor-pointer"
										/>
									</div>
								</div>
								{action && action === i + 1 && (
									<div
										style={{
											boxShadow: "0px 4px 12px 0px #0000001A",
										}}
										className="tw-absolute tw-top-28 tw-w-64 tw-right-0 tw-p-4 tw-bg-white tw-border-[0.5px] tw-border-[#25252580] tw-rounded-xl">
										<div className="tw-flex tw-justify-end">
											<AiOutlineClose onClick={() => setAction(false)} />
										</div>
										<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
											Edit Information
										</h4>
										<div className="tw-mt-3 tw-space-y-4">
											{actions?.map(act => (
												<div
													className="tw-flex tw-items-center tw-cursor-pointer"
													onClick={() => {
														if (act?.action) act?.action(i);
														setAction(false);
													}}>
													<div className="tw-w-8">
														<img src={act?.icon} alt="" className="" />
													</div>
													<h6 className="tw-text-base tw-capitalize tw-font-normal tw-text-secondary">
														{act?.name}
													</h6>
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						))}
						<div
							className="tw-flex tw-gap-2 tw-items-center tw-mt-2"
							onClick={() =>
								setModules(prev => {
									return [...prev, { title: "" }];
								})
							}>
							<img src={Add} alt="" className="" />
							<h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
								Add Another Module
							</h6>
						</div>
						<div className="tw-mt-16 tw-flex tw-items-center tw-gap-6">
							{/* <MainBtn
								onClick={() => {
									if (datum || course) {
										handleSubmit()
									} else {
										setStep(4);
									}
								}}
								text={"Continue"}
								/> */}
							<Button
								onClick={e => {
									e?.preventDefault();
									if (datum || course) {
										handleSubmit(e);
									} else {
										setStep(4);
									}
								}}
								// onClick={handleSubmit}
								loading={loading}
								children={"Continue"}
								type="submit"
							/>
							{!datum && !course && (
								<h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
									Skip
								</h6>
							)}
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export const AddAsessMent = ({ setStep }) => {
  return (
    <div className="tw-w-1/2">
      <h4 className="tw-text-xl tw-font-bold tw-text-secondary">
        Course Assessment
      </h4>
      <form action="" className="tw-mt-8">
        <TextInput label={"Question 1"} />
        <h6 className="tw-text-base tw-font-bold tw-text-secondary tw-my-6">
          Answer Options
        </h6>
        <div className="tw-mt-5 tw-flex tw-gap-6">
          <div className="tw-w-40 tw-h-12 tw-bg-[#E9EEF2] tw-rounded tw-flex tw-justify-between tw-items-center tw-px-4">
            <h6 className="tw-text-sm tw-font-normal tw-text-secondary">
              Multiple Answer
            </h6>
            <input
              type="radio"
              name="answer_option"
              className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
            />
          </div>
          <div className="tw-w-40 tw-h-12 tw-bg-[#E9EEF2] tw-rounded tw-flex tw-justify-between tw-items-center tw-px-4">
            <h6 className="tw-text-sm tw-font-normal tw-text-secondary">
              Single Answer
            </h6>
            <input
              type="radio"
              name="answer_option"
              className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
            />
          </div>
        </div>
        <div className="tw-space-y-4 tw-mt-8">
          <div>
            <h6 className="tw-text-base tw-font-medium tw-text-secondary tw-my-2">
              Option 1
            </h6>
            <div className="tw-h-12 tw-w-full tw-border-[0.5px] tw-border-secondary tw-rounded-lg tw-flex tw-items-center tw-px-3">
              <input
                type="checkbox"
                name=""
                className="tw-h-4 tw-w-4 tw-mr-2"
                id=""
              />
              <input
                type="text"
                name=""
                id=""
                className="tw-h-full tw-w-full tw-pl-2 tw-text-base tw-font-normal tw-text-secondary"
              />
              <div className="tw-flex tw-items-center tw-gap-3 tw-ml-2">
                <h6 className="tw-text-sm tw-whitespace-nowrap tw-font-normal tw-text-[#0269D0]">
                  Correct Answer
                </h6>
                <input
                  type="checkbox"
                  name=""
                  className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
                  id=""
                />
              </div>
            </div>
          </div>
          <div>
            <h6 className="tw-text-base tw-font-medium tw-text-secondary tw-my-2">
              Option 1
            </h6>
            <div className="tw-h-12 tw-w-full tw-border-[0.5px] tw-border-secondary tw-rounded-lg tw-flex tw-items-center tw-px-3">
              <input
                type="checkbox"
                name=""
                className="tw-h-4 tw-w-4 tw-mr-2"
                id=""
              />
              <input
                type="text"
                name=""
                id=""
                className="tw-h-full tw-w-full tw-pl-2 tw-text-base tw-font-normal tw-text-secondary"
              />
              <div className="tw-flex tw-items-center tw-gap-3 tw-ml-2">
                <h6 className="tw-text-sm tw-whitespace-nowrap tw-font-normal tw-text-[#0269D0]">
                  Correct Answer
                </h6>
                <input
                  type="checkbox"
                  name=""
                  className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
                  id=""
                />
              </div>
            </div>
          </div>
          <div>
            <h6 className="tw-text-base tw-font-medium tw-text-secondary tw-my-2">
              Option 1
            </h6>
            <div className="tw-h-12 tw-w-full tw-border-[0.5px] tw-border-secondary tw-rounded-lg tw-flex tw-items-center tw-px-3">
              <input
                type="checkbox"
                name=""
                className="tw-h-4 tw-w-4 tw-mr-2"
                id=""
              />
              <input
                type="text"
                name=""
                id=""
                className="tw-h-full tw-w-full tw-pl-2 tw-text-base tw-font-normal tw-text-secondary"
              />
              <div className="tw-flex tw-items-center tw-gap-3 tw-ml-2">
                <h6 className="tw-text-sm tw-whitespace-nowrap tw-font-normal tw-text-[#0269D0]">
                  Correct Answer
                </h6>
                <input
                  type="checkbox"
                  name=""
                  className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
                  id=""
                />
              </div>
            </div>
          </div>
          <div>
            <h6 className="tw-text-base tw-font-medium tw-text-secondary tw-my-2">
              Option 1
            </h6>
            <div className="tw-h-12 tw-w-full tw-border-[0.5px] tw-border-secondary tw-rounded-lg tw-flex tw-items-center tw-px-3">
              <input
                type="checkbox"
                name=""
                className="tw-h-4 tw-w-4 tw-mr-2"
                id=""
              />
              <input
                type="text"
                name=""
                id=""
                className="tw-h-full tw-w-full tw-pl-2 tw-text-base tw-font-normal tw-text-secondary"
              />
              <div className="tw-flex tw-items-center tw-gap-3 tw-ml-2">
                <h6 className="tw-text-sm tw-whitespace-nowrap tw-font-normal tw-text-[#0269D0]">
                  Correct Answer
                </h6>
                <input
                  type="checkbox"
                  name=""
                  className="tw-h-4 tw-w-4 tw-border-[0.5px] tw-border-secondary"
                  id=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-gap-2 tw-mt-2 tw-items-center">
          <img src={Add} alt="" className="" />
          <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
            Add Another Question
          </h6>
        </div>
        <div className="tw-mt-16 tw-flex tw-items-center tw-gap-6">
          <MainBtn onClick={() => setStep(5)} text={"Continue"} />
          <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
            Skip
          </h6>
        </div>
      </form>
    </div>
  );
};

export const AddPricing = ({ setStep }) => {
  const [modal, setModal] = useState("");
  return (
    <div className="tw-w-1/2">
      <h4 className="tw-text-xl tw-font-bold tw-text-secondary">
        Course Pricing
      </h4>
      <form action="" className="tw-mt-5">
        <TextInput label={"Course Price"} placeholder={"1"} />
        <div
          onClick={() => setModal("coupon")}
          className="tw-flex tw-cursor-pointer tw-gap-2 tw-mt-2 tw-items-center"
        >
          <img src={Add} alt="" className="" />
          <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
            Add Another Coupon
          </h6>
        </div>
        <div className="tw-bg-[#0269D01A] tw-rounded-lg tw-mt-6 tw-w-full tw-h-16 tw-flex tw-justify-between tw-items-center tw-px-5">
          <div>
            <h6 className="tw-text-sm tw-font-medium tw-text-skyblue">
              Coupon Code
            </h6>
            <p className="tw-text-base tw-font-medium tw-text-skyblue">
              ykmhhh71790-2
            </p>
          </div>
          <div>
            <FaRegCopy color="#013467" />
            <p className="tw-text-sm tw-font-medium tw-text-skyblue">Copy</p>
          </div>
        </div>
        <div className="tw-mt-16 tw-flex tw-items-center tw-gap-6">
          <MainBtn onClick={() => setStep(5)} text={"Upload Course"} />
          <h6 className="tw-text-base satoshi tw-font-medium tw-text-myblue tw-underline tw-cursor-pointer">
            Skip
          </h6>
        </div>
      </form>
      {modal === "coupon" && (
        <AddCouponModal handleClose={() => setModal("")} />
      )}
    </div>
  );
};

export default AddCourseNew;
