import React from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import LeaderShipImg from "../../assets/Leadership.png";
import { useNavigate } from "react-router-dom";

const Template = () => {
  const navigate = useNavigate();
  const Arr = [
    {
      img: LeaderShipImg,
      title: "Leadership",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: LeaderShipImg,
      title: "Leadership",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: LeaderShipImg,
      title: "Leadership",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
    {
      img: LeaderShipImg,
      title: "Leadership",
      desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
    },
  ];
  return (
    <div>
      <MainContainer>
        <MainHeader text="Template" small={"Template"} />
        <div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-4 tw-items-center">
              <h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
                All Templates
              </h5>
              <SearchInput />
            </div>
          </div>
          <div className="tw-mt-6 tw-grid lg:tw-grid-cols-4 tw-gap-6">
            {Arr?.map((item, i) => (
              <TemplateCard
                key={i}
                Img={item?.img}
                title={item?.title}
                desc={item?.desc}
                onClick={() => navigate("/courses/single-template")}
              />
            ))}
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export const TemplateCard = ({ Img, title, desc, onClick }) => {
  return (
    <div
      style={{
        border: "0.5px solid rgba(1, 52, 103, 0.30)",
      }}
      className="tw-h-80 tw-relative tw-bg-white tw-rounded-xl tw-w-full"
    >
      <img
        src={Img}
        alt=""
        className="tw-rounded-t-2xl object-fit-contain tw-w-full tw-h-36"
      />
      <div className="tw-p-4">
        <h5 className="tw-font-medium tw-text-secondary tw-text-base satoshi">
          {title}
        </h5>
        <h6 className="tw-text-xs tw-text-main satoshi">{desc}</h6>
        <h6
          onClick={onClick}
          className="tw-text-sm tw-absolute tw-bottom-2 tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer tw-line-clamp-3"
        >
          Use Template
        </h6>
      </div>
    </div>
  );
};

export default Template;
